import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const SEO = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription } =
    useSiteMetadata()

  const pageTitle = title ? ` | ${title}` : ''
  const seo = {
    title: `${defaultTitle}${pageTitle}`,
    description: description || defaultDescription,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {children}
    </>
  )
}

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)
  return data.site.siteMetadata
}

export default SEO
