import * as React from 'react'
import PageView from '../views/pageView'
import data from '../data/contact.json'
import Seo from '../components/seo'

export default function Contact() {
  return <PageView data={data} />
}

export const Head = () => <Seo title="Contact" />
